* {
  letter-spacing: 1px;
}

#root {
  max-width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Instrument Sans', sans-serif;
  color: #5a8967;
  font-size: 11pt;
  background-color: #e5dac0;
  max-width: 100%;
}

a {
  color: #5a8967;
  text-decoration: none;
  font-weight: 600;
}

a:hover {
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}