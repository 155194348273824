.gallery {
    width: 70%;
    margin: 0 auto;
    max-width: 2000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.gallery-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    object-position: center;
}

.row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0;
    margin-bottom: 100px;
    justify-content: center;
    gap: 0.5%;
    width: 100%;
}

.img-container-focus {
    box-sizing: border-box;
    border: 4px solid #d1b63a;
    width: 19%;
    height: 15vh;
    overflow: hidden;
    margin-bottom: 0.5%;
}

.img-container {
    width: 19%;
    height: 15vh;
    overflow: hidden;
    margin-bottom: 0.5%;
}

.curr-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.curr-img-container {
    height: 70vh;
    width: 97%;
    overflow: hidden;
    margin: 10px auto 20px auto;
}