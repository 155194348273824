header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2%;
}

.nav-logo img {
  margin-top: 15px;
  height: 100px;
}

.nav-area {
  height: 50px;
  width: 100%;
  border-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 2%;
}

.menus {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  font-size: 10pt;
  font-weight: 600;
}

.menu-items {
  position: relative;
}

.menu-items a {
  display: block;
}

.menu-items button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  color: inherit;
  font-size: inherit;
  font-weight: 600;
  padding: 0;
  margin-top: 0;
}

.menu-items button:hover {
  text-decoration: underline;
}

.submenu-items a {
  color: #d1b63a;
  padding: 5px 10px;
  display: flex;
  font-size: 10pt;
  font-weight: 600;
  justify-content: center;
  border-bottom: 1px solid #e5dac0;
  min-width: 100px;
}

.submenu-items a:hover {
  text-decoration: none;
  background-color: #456b50;
}

.arrow {
  font-size: 8pt;
  font-weight: 400;
  display: inline-flex;
}

.dropdown {
  display: none;
  position: absolute;
  margin-top: 5px;
  left: 0;
  width: max-content;
  height: fit-content;
  list-style: none;
  padding: 0;
}

.dropdown.show {
  display: flex;
  flex-direction: column;
  background-color: #5a8967;
}

.contact {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  list-style: none;
  margin-right: 0;
  padding-right: 30px;
  font-size: 10pt;
  font-weight: 600;
}

.nav-contact {
  height: 50px;
  width: 50%;
  border-bottom: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  padding: 2%;
  z-index: 2;
}

.icon {
  margin-right: 10px;
  font-size: 12pt;
}

@media (max-width: 767px) {
  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    margin-bottom: 20px;
  }

  .nav-logo img {
    height: 60px;
  }

  .nav-area {
    display: inline;
  }

  .menus {
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    gap: 10px;
  }

  .nav-contact {
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    height: fit-content;
  }

  .nav-contact span {
    display: none;
  }
}
