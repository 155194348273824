.cater-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 40px 0;
  max-width: 1000px;
  width: 60vw;
  background-color: #f1e8d5;
}

.menu-title {
  font-family: "Yeseva One", serif;
  font-size: 45pt;
  margin-top: 10px;
  margin-bottom: 0;
}

.price {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 10pt;
}

.choose-one {
  display: flex;
  justify-content: center;
  font-style: italic;
  font-weight: bold;
  margin: 20px auto 0 auto;
  font-size: 10pt;
  text-align: center;
  width: 100%;
}

.section {
  width: 80%;
  margin: 0 auto;
}

.taco-includes {
  font-style: italic;
  font-size: 10pt;
  margin-bottom: 10px;
}

.cater-menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0 0 0;
}

.item-title {
  font-weight: 800;
  font-size: 14pt;
  margin: 10px 0 5px 0;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.item-info {
  font-weight: 400;
  margin: 5px 20px 0 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.separate {
  display: flex;
  justify-content: center;
  font-weight: 800;
  font-size: 15pt;
  margin-top: 25px;
  margin-bottom: 12px;
  line-height: 0px;
  letter-spacing: 15px;
}

.includes {
  font-style: italic;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 9.5pt;
}

@media (max-width: 767px) {
  .menu-title {
    font-size: 23pt;
    margin-bottom: 20px;
  }

  .cater-menu {
    width: 80%;
  }

  /* .item-title {
    display: flex;
    justify-content: flex-start;
  } */
}
