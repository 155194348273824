.form-container {
  width: 38%;
  margin: 2vh auto;
}

.booking-form {
  width: 100%;
}

.booking-form input,
select {
  width: 100%;
  height: 30px;
}

.comments textarea {
  height: 50pt;
  width: 97%;
  max-width: 100%;
  max-height: 50pt;
  padding-top: 5px;
}

.captcha {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.submit {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

label {
  font-size: 10pt;
  font-weight: 600;
}

.form-field {
  margin-top: 5px;
  margin-bottom: 10px;
}

.small-bites select {
  margin-bottom: 10px;
}

.form-field input,
textarea {
  margin-top: 5px;
  padding-left: 10px;
  font-size: 10pt;
  color: #5a8967;
  width: 97%;
  background-color: #f1e8d5;
  border: 0px;
}

.form-field input::placeholder,
textarea::placeholder {
  color: #86a690;
}

.menu-title {
  display: flex;
  justify-content: center;
}

select {
  appearance: none;
  background-color: #f1e8d5;
  border: 0px;
  color: #5a8967;
  padding: 0 1em 0 0;
  margin: 5px 0;
  width: 100%;
  font-family: inherit;
  font-size: 10pt;
  cursor: inherit;
  line-height: inherit;
  padding-left: 10px;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 15px) calc(1em + 1px),
    calc(100% - 10px) calc(1em + 1px), calc(100% - 2em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.submit button,
.submit button:disabled {
  width: 300px;
  height: 40px;
  font-size: 10pt;
}

.submit button {
  cursor: pointer;
  color: #d1b63a;
  font-weight: 600;
  border: 1px solid #456b50;
  background-color: #5a8967;
}

.submit button:disabled {
  cursor: not-allowed;
  background-color: lightgray;
  color: gray;
  border: 1px solid gray;
}

.error {
  color: red;
  font-size: 8pt;
  margin-top: 5px;
}

@media (max-width: 600px) {
  .form-container {
    width: 100%;
  }
}
