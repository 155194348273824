footer {
    margin-top: auto;
    padding-top: 40px;
    margin-bottom: 30px;
}

.copyright {
    font-size: 8pt;
    display: flex;
    justify-content: center;
}